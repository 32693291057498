<template>
  <v-card
    class="d-flex align-center justify-center"
    :style="
      $vuetify.theme.dark
        ? 'border: thin solid rgba(255, 255, 255, 0.12)'
        : 'border: thin solid rgba(0, 0, 0, 0.12)'
    "
    elevation="0"
  >
    <v-slide-x-reverse-transition>
      <v-btn
        v-show="photoPreview && !visibleLoadImg"
        absolute
        style="right: 8px; top: 8px; z-index: 105"
        class="btn-edit"
        color="error"
        @click="resetImage()"
        ><v-icon class="text-white">mdi-image-remove-outline</v-icon></v-btn
      ></v-slide-x-reverse-transition
    >
    <label for="photo_add" v-if="!photoPreview">
      <div class="text-center d-flex justify-center">
        <div
          :style="`width: ${customWidth}px; height: 200px`"
          class="photo-area br-5 d-flex justify-center align-center"
        >
          <div :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
            <v-icon>mdi-image-plus-outline</v-icon><br />
            {{ $t("home.add_photo") }}
          </div>
        </div>
      </div>
    </label>
    <v-img
      v-else
      :src="photoPreview"
      @click="showImgDialog()"
      :style="`width: ${customWidth}px; height: 200px; object-fit: contain; border-radius: 4px;`"
      :class="typeof image == 'string' ? 'cursor-pointer' : ''"
      class="br-5"
    />
    <VFileInput
      v-if="!visibleLoadImg"
      class="d-none"
      id="photo_add"
      accept="image/png, image/jpeg, image/jpg, image/gif"
      v-model="image"
      @change="makePreview()"
    />
    <imgDialog
      :visible="imgDialog"
      @close="imgDialog = false"
      :imgpath="imgpath"
    />
  </v-card>
</template>

<script>
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import fileSizeValidation from "@/validators/fileSizeProduct";
import imgDialog from "@/components//dialog/imgDialog.vue";
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    photoPreview: null,
    image: null,
    imgDialog: false,
    imgpath: "",
  }),
  mixins: [notifications, validationMixin],
  validations: {
    image: { fileSizeValidation },
  },
  props: {
    customWidth: {
      required: false,
      default: 450,
    },
    visibleLoadImg: {
      require: false,
      default: false,
    }
  },
  components: {
    imgDialog,
  },
  mounted() {
    EventBus.$on("is-selected-image", (selectedImage) => {
      this.image = `${selectedImage}`;
      this.photoPreview = this.image;
      this.$emit("editChangeImage", this.image);
    });
    EventBus.$on("pasted-image", (pastedImage) => {
      this.makePreview(pastedImage);
    });
  },
  created() {
    this.originalError = console.error;
    console.error = (msg, ...args) => {
      if (
        !msg.includes(
          'Invalid prop: custom validator check failed for prop "value"'
        )
      ) {
        this.originalError(msg, ...args);
      }
    };
  },
  beforeDestroy() {
    console.error = this.originalError;
    EventBus.$off("is-selected-image");
    EventBus.$off("pasted-image");
  },
  methods: {
    makePreview(pastedImage = null) {
      if (this.$v.image.fileSizeValidation) {
        let photoPreview = null;
        const reader = new FileReader();
        if (pastedImage != null) {
          reader.readAsDataURL(pastedImage);
        } else {
          reader.readAsDataURL(this.image);
        }
        reader.onloadend = (ev) => {
          photoPreview = ev.target.result;
          this.photoPreview = photoPreview;
        };
        if (pastedImage == null) {
          this.$emit("success", this.image);
        }
      } else {
        this.image = null;
        this.photoPreview = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSize"));
      }
    },
    resetImage() {
      this.photoPreview = null;
      this.image = null;
      this.$emit("reset");
    },
    showImgDialog() {
      if (typeof this.image === "string") {
        this.imgpath = this.image;
        this.imgDialog = true;
      }
    },
  },
};
</script>

<style>
.photo-area:hover {
  cursor: pointer;
  transition: opacity 0.4s;
  opacity: 0.7;
}
</style>
