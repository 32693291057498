<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    @click:outside="$emit('close')"
  >
    <v-card class="pb-4">
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center" style="position: relative">
        {{ $t('boards.settings.activity') }}
        <div class="justify-start d-flex"
          style="position: absolute;
          width: 100%;
          margin-left: 40px;">
          
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="px-3 rounded" style="border-color: #ff6c00;
                border-width: 1px;
                border-style: solid;
                background-color: #ff83005e;" v-bind="attrs" v-on="on">
                <h5>{{ page }}</h5>
              </div>
            </template>
            <span>{{ $t("form.History.Page") }}</span>
          </v-tooltip>
        </div>
        <v-fade-transition>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="orange"
            absolute
            style="bottom: -2px"
          />
        </v-fade-transition>
      </v-card-title>
      <v-divider v-if="!loading" class="mb-4 mx-4" />
      <v-card-text
        :class="loading ? 'mt-4' : ''"
        style="height: 400px; overflow-y: auto"
        class="pb-0 px-3 pt-3"
      >
        <v-row v-if="HistoryList.length">
          <v-col
            cols="12"
            class="pt-0 pb-0"
            v-for="(item, index) in HistoryList"
            :key="index"
            :ref="index === 0 ? 'firstActivity' : null"
          >
          <!-- v-if="item.type == 'history'" -->
            <v-col
              cols="auto"
              class="d-flex align-center pb-0 px-0 pt-2"
            >
              <t-avatar :user="item.creator" />
              <span class="px-2 font-weight-bold font-14">{{
                item.creator.username
              }}</span>
              <span class="font-weight-medium">{{ GetData(item.created_at) }}</span>
            </v-col>
            <v-col cols="12" class="px-10 py-0"> <!-- v-if="item.type == 'history'" -->
              <div
                class="font-weight-bold"
                :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
              >
                {{ item.message }}
              </div>
            </v-col>

            <v-col v-if="item.device_old && item.device_new" cols="11" class="px-2 ml-9 py-0" style="border: 2px solid #ff7a00; border-radius: 5px;">
             <!--  <div
                class="font-weight-bold"
                :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
              >
                <span class="font-weight-bold font-14">{{
                  $t('form.cardname')
                }}</span>
              </div> -->
              <div class="d-flex justify-space-between">
                <span style="font-size: 11px;"> Cтарі дані </span>
                <span style="font-size: 11px;"> Нові дані</span>
              </div>
              <v-divider />
              <div v-if="item.device_old.device_media != null || item.device_new.device_media != null" class="py-2 d-flex justify-space-between">
                <v-img style="border-radius: 5px; max-width: 80px; min-width: 80px;" :src="item.device_old.device_media" />
                <v-img style="border-radius: 5px; max-width: 80px; min-width: 80px;" :src="item.device_new.device_media" />
              </div>
              <div v-if="item.device_old.name != null || item.device_new.name != null" class="d-flex justify-space-between">
                <span class="mr-3"> {{ item.device_old.name ? item.device_old.name : '--' }} </span>
                <span> {{ item.device_new.name ? item.device_new.name : '--' }} </span>
              </div>
              <div v-if="item.device_old.inventory_id != null || item.device_new.inventory_id != null" class="d-flex justify-space-between">
                <span class="mr-3"> {{ item.device_old.inventory_id ? item.device_old.inventory_id : '--' }} </span>
                <span> {{ item.device_new.inventory_id ? item.device_new.inventory_id : '--' }} </span>
              </div>
              <div v-if="item.device_old.comment != null || item.device_new.comment != null" class="d-flex justify-space-between">
                <span class="mr-3"> {{ item.device_old.comment ? item.device_old.comment : '--'	 }} </span>
                <span> {{ item.device_new.comment ? item.device_new.comment : '--'	 }} </span>
              </div>
              <div v-if="item.device_old.status != null || item.device_new.status != null" class="d-flex justify-space-between">
                <span class="mr-3"> {{ item.device_old.status ? item.device_old.status : '--' }} </span>
                <span> {{ item.device_new.status ? item.device_new.status : '--' }} </span>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row v-if="!HistoryList.length" class="d-flex align-center justify-center">
          <strong>{{ $t('form.noData') }}</strong>

        </v-row>
        <v-row
          no-gutters
          class="pt-4 mb-1"
          v-observer="{ nextPage: nextPage }"
          v-if="!loadMoreDisabled"
          ><v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="height: 50px"
          >
            <v-progress-circular
              indeterminate
              color="orange"
              size="35"
              width="3"
            /> </v-col
        ></v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";
import cardsService from "@/services/request/cards/cardsService";
import projectsService from "@/services/request/projects/projectsService";

export default {
  mixins: [dialogMixin, notifications],
  data: () => ({
    HistoryList: [],
    visibleDialog: false,
    dialogType: "",
    loading: false,
    page: 1,
    loadMoreDisabled: false,
  }),
  props: {
    device: {
      require: false,
    }
  },
  async mounted() {
    this.loading = true;
    //await this.getActivity();
    //console.log("mounted Device history :", this.device);
    await this.getDeviceHistory();
    this.loading = false;
  },
  methods: {
    async getDeviceHistory() {
      const params = {};
      params.per_page = 9999;
      const DeviceHistory = await projectsService.getDeviceHistory(this.device.project_id, this.device.id, params);
      this.HistoryList = DeviceHistory;
      console.log("Get Device history :", DeviceHistory);

      this.loadMoreDisabled = true;
    },

    async getActivity(isUpdated = false) {
      try {
        const params = {};
        params.locale = this.currentLanguage.key;
        params.page = this.page;
        let activityItems = await cardsService.getBoardsActivity(
          this.copiedUuid,
          this.copiedUuidBoard,
          params
        );
        console.log("Params : ", activityItems);
        activityItems?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        activityItems = activityItems?.map((activity) => ({
          cardName: activity?.card?.name,
          user: activity.user,
          username: activity.user.username,
          surname: "",
          date: new Date(activity.created_at).toLocaleString(this.currentLanguage.key, {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
          }),
          description: activity.message,
          id: activity.user.id,
          type: activity.type,
          profile_image: activity.profile_image,
        }));
        console.log("activityItems : ", activityItems);
        if (isUpdated) {
          this.HistoryList?.push(...activityItems);
        } else {
          this.HistoryList = activityItems;
        }
        this.loadMoreDisabled = activityItems.length < 15;
      } catch (e) {
        this.setErrorNotification(this.$t('errors.somethingWentWrong'));
      }
    },
    async nextPage() {
      if (this.HistoryList?.length) {
        //this.page++;
        //await this.getActivity(true);
      }
    },
    GetData(isoDate) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(isoDate).toLocaleDateString('uk-UA', options);
    }
  },
  computed: {
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID,
      copiedUuidBoard: getterTypes.GET_COPIED_UUID_BOARD,
    }),
    ...mapGetters(["currentLanguage"]),
  },
};
</script>