<template>
    <v-dialog
        v-model="visibility"
        max-width="500"
        content-class="cross__dialog"
        :transition="$dialogTransition"
        @click:outside="onCancel()"
        scrollable
    >
      <v-card style="min-height: 100px;">
        <v-slide-x-transition>
          <div class="cross__inner">
            <v-btn fab small elevation="0" @click="onCancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-slide-x-transition>

        <div class="ma-2 d-flex justify-space-around">
          <span>{{ $t("form.Project_lable") }}</span>
          <v-divider vertical />
          <span class="mr-8">{{ $t("form.time") }}</span>
        </div>
        <span style="border-bottom: 1px solid #9e9e9e;" />
        
        <v-list nav  style="overflow-y: auto; min-height: auto; max-height: 400px;">
          <v-list-item class="d-flex align-center justify-space-between" v-for="item in timeLines" :key="item.id" @click="SelectProject(item)">
            <div>
              <span class="d-flex align-center justify-space-between">
                <v-avatar v-if="item.project_media" size="35">
                  <v-img :src="item.project_media" alt="project image" />
                </v-avatar>
                <v-avatar v-else size="35" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.name) }}</v-avatar>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="item.flag"
                      small
                      class="ml-6 mt-n7 custom-btn-project btn_flag"
                      style="position: absolute;"
                    >
                      <v-icon size="10">mdi-flag-variant</v-icon>
                    </v-btn>
                  </template>
                    <span>{{ item.comment || "--" }}</span>
                </v-tooltip>
                <div class="mx-2" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ item.name }}</div>
              
              </span>
            </div>
            <div class="ml-auto">{{ getTime(item.minutes_on_project) }}</div>
          </v-list-item>
        </v-list>        
      </v-card>

      <timeProjectUserCheckDialog
          v-if="timePickerDialog"
          :visible="timePickerDialog"
          :TimeUser="selectProjectTime"
          @close="timePickerDialog = false"
      />

    </v-dialog>
</template>

<script>
import notifications from "@/mixins/notifications";
import dialogMixin from "@/mixins/dialogMixin";
import userMixin from "@/mixins/user";
import timeProjectUserCheckDialog from "../boards/dialogs/timeProjectUserCheckDialog.vue";

export default {
  components: { timeProjectUserCheckDialog },
  mixins: [notifications, dialogMixin, userMixin],
  data() {
    return {
      timeLines: [],
      selectProjectTime: null,
      timePickerDialog: false,
    };
  },
  props: {
    projectTime: {
      require: false,
    }
  },
  async mounted() {
      await this.fetchTimeLineCard();
  },
  methods: {
    async SelectProject(project_time) {
      if(!this.projectTime.filteredUser) {
        const { user_id, name, email, surname, username, profile_image, date, date_to, date_from } = this.projectTime;
        const user = { user_id, name, email, surname, username, profile_image };
        this.selectProjectTime = { user, date, date_to, date_from, project_time };
      } else {
        const { date, date_to, date_from } = this.projectTime;
        const { user_id, name, email, surname, username, profile_image } = this.projectTime.filteredUser;
        const user = { user_id, name, email, surname, username, profile_image };
        this.selectProjectTime = { user, date, date_to, date_from, project_time };
      }
      this.timePickerDialog = true;
    },
    onCancel() {
      this.$emit("close");
    },
    async fetchTimeLineCard() {
      this.loading = true;
      try {
        if(!this.projectTime.projects) {
          this.timeLines = this.projectTime.filteredUser.projects;
        } else {
          this.timeLines = this.projectTime.projects;
        }
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
      }
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },

  },
  computed: {
    getTime() {
      return (time) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return `${hours}h ${minutes}m`;
      };
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
@import "src/components/boards/taskDescriptionDialog.scss";
</style>
