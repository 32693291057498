<template>
  <v-card
    v-model="visibility"
    min-width="auto"
    max-width="800"
    :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 40px;':''"
    class="mt-3"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    scrollable
    @click:outside="onCancel()"
  >
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <timePicker class="ml-n15 pt-3 mb-n8" style="max-width: 390px;" @callTheFuction="fetchTime" />
      <v-card-text>
        <v-simple-table v-if="allUsers && allUsers.length || transformedDataList && transformedDataList.length">
          <thead>
          <tr>
            <th></th>
            <th class="date-cell">{{ $t("form.generally") }}</th>
            <th
                v-for="date in dates"
                :key="date"
                class="date-cell"
                :class="{ 'weekend': isWeekend(date) }"
            >
              {{ formatDateWithDay(date) }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="SelectUser == null" v-for="user in allUsers" :key="user.user_id">
            <td>
              <v-col cols="auto" class="d-flex align-center pb-0 px-0 pt-2">
                <t-avatar :user="user" />
                <span class="px-2 font-weight-bold font-14" @click="ClickUser(user)">{{ user.username }}</span>
              </v-col> 
            </td>
            <td class="font-weight-bold date-cell" style="cursor: pointer;" @click="ShowProjectsAllTime(user, dates)">{{ formatMinutes(getTotalMinutesForUser(user.user_id)) }}</td>
            <td v-for="date in dates" :key="date" class="date-cell" style="cursor: pointer;" :class="{ 'weekend': isWeekend(date) }" @click="ShowProjectsTime(user, date)">{{ formatMinutes(getMinutesForUserOnDate(user.user_id, date)) }}</td>
          </tr>          
          <tr v-if="SelectUser != null" v-for="item in transformedDataList" :key="item.project_id">
            <td>
              <v-col cols="auto" class="d-flex align-center pb-0 px-0 pt-2">
                  <v-avatar v-if="item.project_media" size="35" class="mr-3">
                    <v-img :src="item.project_media" alt="project image" />
                  </v-avatar>
                  <v-avatar v-else size="35" class="orange text--lighten-1 mr-3" style="font-weight: 700; cursor: default;">{{ getLetters(item.project_name) }}</v-avatar>
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :color="item.project_flag"
                        small
                        class="ml-n6 mt-n6 custom-btn-project btn_flag"
                      >
                        <v-icon size="10">mdi-flag-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.project_comment || "--" }}</span>
                  </v-tooltip>
                  <div class="ml-2">{{ item.project_name }}</div>
              </v-col>
            </td>
            <td class="font-weight-bold date-cell">{{ formatMinutes(getTotalMinutesForUser(item.project_id)) }}</td>
            <td v-for="date in dates" :key="date" class="date-cell" :class="{ 'weekend': isWeekend(date) }">{{ formatMinutes(getMinutesForUserOnDate(item.project_id, date)) }}</td>
          </tr>
          </tbody>
        </v-simple-table>
        <div v-if="allUsers && allUsers.length || transformedDataList && transformedDataList.length" class="d-flex justify-space-between mt-3 align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="usersTimesList && usersTimesList.length > 0" color="orange" icon v-bind="attrs" v-on="on" @click="ExportTableProject(usersTimesList)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn v-else color="orange" icon v-bind="attrs" v-on="on" @click="ExportTableUser()">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.statisticExportLable") }}</span>
          </v-tooltip>
          <div class="d-flex">
            <strong class="mr-1">{{ $t("form.total") }}</strong> {{ formatMinutes(totalMinutes) }}
          </div>
        </div>
        <div v-if="allUsers && !allUsers.length && !loading && transformedDataList && !transformedDataList.length && !loading" class="d-flex align-center justify-end mt-5">
          <strong>{{ $t('form.noData') }}</strong>
        </div>
      </v-card-text>

      <ShowTimeProjectUser 
        v-if="visibleShowTimeforProject"
        :visible="visibleShowTimeforProject"
        @close="visibleShowTimeforProject = false"
        :projectTime="projectTime"
      />
      
  </v-card>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import notifications from "@/mixins/notifications";
import projectsService from "@/services/request/projects/projectsService";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import userMixin from "@/mixins/user";
import {mapGetters} from "vuex";
import TimePicker from "@/components/UI/timePicker.vue";
import ShowTimeProjectUser from "./ShowTimeProjectUser.vue";

export default {
  mixins: [dialogMixin, validationMixin, notifications, userMixin],
  data: () => ({
    loading: false,
    headers: [
      { text: 'Date/Users', value: 'date' },
      { text: 'Minutes', value: 'users' },
      { text: 'Description', value: 'description' },
    ],
    usersTimesList: [],
    usersProjects: [],
    transformedData: [],
    visibleShowTimeforProject: false,
    projectTime: [],
    total_time_perUser: [],
  }),
  validations: {
    addUserForm: {
      email: { required, email },
    },
  },
  props: {
    UsersAll: {
      required: false,
    },
    project: {
      required: false,
    },
    SelectUser: {
      required: false,
    },
    selectUserID: {
      required: false,
    },
    selectUserName: {
      required: false,
    },
    location: {
      required: false,
      default: "project",
    },
  },
  components: {
    TimePicker,
    ConfirmLeftDialog,
    ConfirmDialog,
    ShowTimeProjectUser
  },
  methods: {

    ShowProjectsAllTime(user, dates) {
      let filteredUser = this.total_time_perUser.find(u => u.user_id === user.user_id);
      if (filteredUser) {
        this.projectTime = {
          filteredUser: filteredUser,
          date_from: dates[0],
          date_to: dates[dates.length - 1]
        };
        this.visibleShowTimeforProject = true;
      }
    },

    ShowProjectsTime(user, date) {
      if(!user.projects) {return;}

      const userData = this.usersTimesList.find(item => item.date === date);
      if (userData) {
        const filteredUser = userData.users.find(u => u.user_id === user.user_id);
        if (filteredUser) {
          this.projectTime = filteredUser;
          this.visibleShowTimeforProject = true;
        }
      }
    },

    async ExportTableUser() {
      try {
        if (!this.usersProjects || this.usersProjects.length === 0) {
          this.setErrorNotification("Немає даних для експорту / No data to export");
          return;
        }
        const headers = [this.$t("form.projectName"), this.$t("form.generally"), ...this.usersProjects.map(item => this.formatDateWithDay(item.date))];
        const projectMap = new Map();
        this.usersProjects.forEach(item => {
          const date = this.formatDateWithDay(item.date);
          if (item.projects && item.projects.length > 0) {
            item.projects.forEach(user => {
              if (!projectMap.has(user.project_id)) {
                projectMap.set(user.project_id, {
                  projectName: user.project_name || "--",
                  totalTime: {},
                  times: {}
                });
              }
              projectMap.get(user.project_id).totalTime[date] = user.total_minutes || 0;
              projectMap.get(user.project_id).times[date] = this.formatMinutes(user.total_minutes) || "0h 0m";
            });
          }
        });
        const rows = Array.from(projectMap.values()).map(user => {
          const row = [user.projectName];
          let totalMinutes = 0;
          headers.slice(2).forEach(date => {
            const timeForDate = user.times[date] || "0h 0m";
            const TotaltimeForDate = user.totalTime[date] || 0;
            totalMinutes += TotaltimeForDate;
            row.push(timeForDate);
          });
          row.splice(1, 0, this.formatMinutes(totalMinutes));
          return row;
        });
        const csvContent = [
          headers.join(";"),
          ...rows.map(row => row.join(";"))
        ].join("\n");
        const blob = new Blob([`\uFEFF${csvContent}`], { type: "text/csv;charset=utf-8;" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Statistic_User_" + (this.selectUserName && this.selectUserName || "StatisticUsers") + ".csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setSuccessNotification(this.$t("form.statisticSuccess"));
      } catch (error) {
        this.setErrorNotification(error.message);
      }
    },
    async ExportTableProject(usersTimesList) {
      try {
        if (!usersTimesList || usersTimesList.length === 0) {
          this.setErrorNotification("Немає даних для експорту / No data to export");
          return;
        }
        const headers = [this.$t("form.username"), this.$t("form.generally"), ...usersTimesList.map(item => this.formatDateWithDay(item.date))];
        const userMap = new Map();
        usersTimesList.forEach(item => {
          const date = this.formatDateWithDay(item.date);
          if (item.users && item.users.length > 0) {
            item.users.forEach(user => {
              if (!userMap.has(user.user_id)) {
                userMap.set(user.user_id, {
                  username: user.username || user.name || "--",
                  totalTime: {},
                  times: {}
                });
              }
              userMap.get(user.user_id).totalTime[date] = user.total_minutes || 0;
              userMap.get(user.user_id).times[date] = this.formatMinutes(user.total_minutes) || "0h 0m";
            });
          }
        });
      
        const rows = Array.from(userMap.values()).map(user => {
          const row = [user.username];
          let totalMinutes = 0;
          headers.slice(2).forEach(date => {
            const timeForDate = user.times[date] || "0h 0m";
            const TotaltimeForDate = user.totalTime[date] || 0;
            totalMinutes += TotaltimeForDate;
            row.push(timeForDate);
          });

          row.splice(1, 0, this.formatMinutes(totalMinutes));
          return row;
        });
        const csvContent = [
          headers.join(";"),
          ...rows.map(row => row.join(";"))
        ].join("\n");
        const blob = new Blob([`\uFEFF${csvContent}`], { type: "text/csv;charset=utf-8;" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", (this.project && this.project.name || "StatisticUsers") + ".csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setSuccessNotification(this.$t("form.statisticSuccess"));
      } catch (error) {
        this.setErrorNotification(error.message);
      }
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    async ClickUser(user) {
      this.$emit("UserClick", user);
    },
    getDayOfWeek(date) {
      const daysOfWeekJson = this.$t('form.DataTimeDays');
      const daysOfWeek = JSON.parse(daysOfWeekJson);
      const d = new Date(date);
      return daysOfWeek[d.getDay()];
    },
    isWeekend(date) {
      const day = new Date(date).getDay();
      return day === 6 || day === 0;
    },
    formatDateWithDay(date) {
      const dayOfWeek = this.getDayOfWeek(date);
      const [year, month, day] = date.split('-');
      return `${dayOfWeek} ${month}/${day}`;
    },
    async fetchTime(filterProps) {
      this.loading = true;
      try {
        const params = {};
        if(filterProps.date_created_begin && filterProps.date_created_end) {
          params.date_from = filterProps.date_created_begin;
          params.date_to = filterProps.date_created_end;
        } else if(filterProps.time_created) {
          params.date_from = filterProps.time_created;
          params.date_to = filterProps.time_created;
        }
        if(this.UsersAll == "allUsersStart") {
          const { data, total_time_per_user } = await projectsService.getAllUsers(params);
          this.usersTimesList = data;
          this.total_time_perUser = total_time_per_user;
        }
        else if(this.SelectUser != "select") {
          this.usersTimesList = await projectsService.getUsersTime(this.project.id, params);
        }
        else {
          params.user_id = this.selectUserID;
          this.usersProjects = await projectsService.getUserTimeStatistics(params);
          this.usersProjects.forEach(item => {
            const projects = item.projects;
            if (projects && projects.length !== 0) {
              projects.forEach(project => {
                this.transformedData.push({
                  date: item.date,
                  user_id: this.selectUserID,
                  ...project
                });
              });
            }
          });
        }
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
    },
    getTotalMinutesForUser(userId) {
      let total = 0;
      if(this.SelectUser != "select" || this.UsersAll == "allUsersStart") {
        this.usersTimesList.forEach(item => {
          const user = item.users.find(user => user.user_id === userId);
          if (user) {
          total += user.total_minutes;
        }
      });
      } else {
        this.usersProjects.forEach(item => {
          const user = item.projects.find(user => user.project_id === userId);
          if (user) {
          total += user.total_minutes;
        }
      });
      }
      return total;
    },
    getMinutesForUserOnDate(userId, date) {
      if(this.SelectUser != "select" || this.UsersAll == "allUsersStart") {
        const item = this.usersTimesList.find(item => item.date === date);
        if (!item) return 0;
        const user = item.users.find(user => user.user_id === userId);
        return user ? user.total_minutes : 0;
      } 
      else {
        const item = this.usersProjects.find(item => item.date === date);
        if (!item) return 0;
        const user = item.projects.find(user => user.project_id === userId);
        return user ? user.total_minutes : 0;
      }
    },
    formatMinutes(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours}h ${mins}m`;
    },
  },
  computed: {
    dates() {
      if(this.SelectUser != "select" || this.UsersAll == "allUsersStart") {
        return [...new Set(this.usersTimesList.map(item => item.date))];
      }
      else {
        return [...new Set(this.usersProjects.map(item => item.date))];
      }
    },
    allUsers() {
      const usersMap = new Map();
        this.usersTimesList.forEach(item => {
          item.users.forEach(user => {
            if (!usersMap.has(user.user_id)) {
              usersMap.set(user.user_id, user);
            }
          });
        });
      return Array.from(usersMap.values());
    },
    transformedDataList() {
      const ProjectsMap = new Map();
      this.usersProjects.forEach(item => {
        item.projects.forEach(user => {
          if (!ProjectsMap.has(user.project_id)) {
            ProjectsMap.set(user.project_id, user);
          }
        });
      });
      return Array.from(ProjectsMap.values());
    },
    totalMinutes() {
      if(this.SelectUser != "select" || this.UsersAll == "allUsersStart") {
        return this.allUsers.reduce((total, user) => {
          return total + this.getTotalMinutesForUser(user.user_id);
        }, 0);
      }
      else {
        return this.transformedDataList.reduce((total, user) => {
          return total + this.getTotalMinutesForUser(user.project_id);
        }, 0);
      }
    },
    ...mapGetters(["currentLanguage"]),
  },
};
</script>

<style lang="scss">
.date-cell {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.total-sum {
  position: absolute;
  bottom: -43px;
  right: 2px;
  padding: 10px;
  background: #f0f0f0;
  border-top: 1px solid #ccc;
}
.weekend {
  background-color: #D0D0D0;
}

.theme--dark .weekend {
  background-color: #4a4a4a;
}

</style>
