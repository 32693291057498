<template>
  <v-dialog 
    v-model="visibility"
    max-width="800"
    content-class="cross__dialog"
    :transition="$dialogTransition"
  >
    <v-card style="min-height: 480px; max-height: 480px;">
      <v-fade-transition>
        <v-progress-linear 
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>

      <v-row class="ml-5 mr-12 mt-0 mb-0 pt-5 px-0 align-center justify-space-between">
        <div
          :style="$vuetify.breakpoint.width > 670 
          ? { maxWidth: '197px', minWidth: '197px' } 
          : { maxWidth: 'none', minWidth: '10px' }"
          class="ma-0 pa-0 d-flex align-center" v-if="project">
          <v-avatar v-if="project.project_media" size="35">
            <v-img :src="project.project_media" alt="project image" />
          </v-avatar>
          <v-avatar v-else size="35" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
          <v-list-item-title class="mx-2" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ project.name }}</v-list-item-title>
        </div>

        <v-col cols="auto" :class="$vuetify.breakpoint.width < 671 ? project ? 'mt-4 pa-0 d-flex align-center':'flex-wrap ma-0 pa-0 d-flex align-center' :'ma-0 pa-0 d-flex align-center'" style="gap: 9px;">

          <v-select
              v-if="!project"
              outlined
              :items="CommonProjects"
              item-text="name"
              return-object
              hide-details
              color="orange"
              item-color="orange"
              :placeholder="$t('form.DeviceDialog.deviceProjectName')"
              v-model="SelectProject"
              style="max-width: 230px; min-width: 230px;"
              @change="selectProjectMethod"
              clearable
              dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" :disabled="item.disabled" :class="{ 'custom-disabled': item.disabled }">
                  <v-list-item-content>

                    <v-list-item-title v-if="item.myProj">
                      <span style="font-size: 15px;">{{ item.name }}</span>
                    </v-list-item-title>

                    <v-list-item-title v-if="!item.disabled && !item.myProj">
                      <v-avatar v-if="item.project_media" size="30">
                        <v-img :src="item.project_media" alt="project image" />
                      </v-avatar>
                      <v-avatar v-else size="30" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.name) }}</v-avatar>
                      
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :color="item.flag"
                            small
                            class="ml-n2 mt-0 custom-btn-project btn_flag"
                            style="position: absolute;"
                          >
                            <v-icon size="10" color="white">mdi-flag-variant</v-icon>
                          </v-btn>
                        </template>
                          <span>{{ item.comment || "--" }}</span>
                      </v-tooltip>
                      
                      <span class="pl-4">{{ item.name }}</span>
                    </v-list-item-title>

                    <v-list-item-title v-else-if="!item.myProj">
                      <span style="font-size: 15px;">{{ item.name }}</span>
                    </v-list-item-title>

                    <div v-if="!isLoader_page && item === lastProject"
                      class="observer"
                      style="height: 1px;"
                      v-observer="{ nextPage: MoreProjects }">
                    </div>
                    
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-spacer />
                    <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <div v-if="item.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div>
                    <div v-if="item.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="item.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>

            </template>
            
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center">
                <v-avatar v-if="item.project_media" size="30">
                      <v-img :src="item.project_media" alt="project image" />
                    </v-avatar>
                    <v-avatar v-else size="30" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.name) }}</v-avatar>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :color="item.flag"
                          small
                          class="ml-5 mb-4 custom-btn-project btn_flag"
                          style="position: absolute;"
                        >
                          <v-icon size="10" color="white">mdi-flag-variant</v-icon>
                        </v-btn>
                      </template>
                        <span>{{ item.comment || "--" }}</span>
                    </v-tooltip>
                  <span class="pl-2">{{ item.name.length > 12 ? item.name.slice(0, 12) + '...' : item.name }}</span>
              </div>
            </template>
              <!-- <template v-slot:append>
                <v-icon v-if="DeviceData.status == 'Issued' && !UserSelect" color="green">mdi-check</v-icon>
                <v-icon v-if="DeviceData.status == 'In_Transfer' || UserSelect" color="orange">mdi-clock-outline</v-icon>
              </template> -->
              
            </v-select>

          <v-text-field
            v-model="searchDevice"
            @change="SearchDeviceMethod"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('form.search')"
            maxLength="25"
            color="orange"
            style="min-width: 160px; max-width: 160px; transition: all 0.4s"
          />

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" outlined color="orange" :class="{ 'hover-effect': IsDeviceBtn }" style="max-width: 45px; min-width: 45px;" @click="AllDeviceMethod">
                <v-icon>mdi-monitor-cellphone-star</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.DeviceDialog.AllDevices") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" outlined color="orange" :class="{ 'hover-effect': !IsDeviceBtn }" style="max-width: 45px; min-width: 45px;" @click="MyDeviceMethod">
                <v-icon>mdi-account-card-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.DeviceDialog.MyDevices") }}</span>
          </v-tooltip>

          <v-btn v-if="project" class="btn-orange-bg" :style="$vuetify.breakpoint.width < 857 
            ? { maxWidth: '40px', minWidth: '40px' } 
            : { color: 'whitesmoke', maxWidth: '200px', minWidth: '200px' }"
            @click="AddDevice">
            <v-icon v-if="$vuetify.breakpoint.width > 857" left size="20">mdi-plus</v-icon>
            <v-icon v-else size="23">mdi-plus</v-icon>
            <span v-if="$vuetify.breakpoint.width > 857">{{ $t("form.DeviceDialog.addDevice") }}</span>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="orange" small icon v-bind="attrs" v-on="on" @click="ExportListDevice()">
                <v-icon size="20">mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.statisticExportLable") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider class="mt-3"/>

<!--  <div class="mt-2 mb-2 d-flex justify-end mr-5">
        <v-text-field
          v-model="searchDevice"
          @change="SearchDeviceMethod"
          outlined
          dense
          hide-details
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('form.search')"
          maxLength="25"
          color="orange"
          style="min-width: 200px; max-width: 200px; transition: all 0.4s"
        />
      </div>
      
      <v-divider/> -->
      
      <div v-if="deviceList.length == 0 && !loading" class="d-flex flex-column justify-center align-center mt-2" style="opacity: 0.5;">
        <v-icon size="40">mdi-devices</v-icon>
        {{ $t("form.DeviceDialog.NoDevices") }}
      </div>

      <v-list nav style="overflow-y: auto; min-height: auto; max-height: 400px;" class="justify-space-between">
        <v-list-item :class="item.user_id == getUserData.id ? 'yourAccount':''" v-for="(item, index) in deviceList" :key="item.id" @click="SelectDevice(item)"
          :style="
            ($vuetify.theme.dark 
            ? 'background-color: #ffffff08' 
            : 'background-color: rgba(0, 0, 0, 0.08)')"
        >
          <div class="mr-4 ml-2" style="color: #9e9e9e;">{{ index + 1 }}</div>
          <div>
            <v-list-item-subtitle style="font-size: 12px;">
              {{ item.inventory_id == null ? '': item.inventory_id }}
            </v-list-item-subtitle>
            {{ item.name }}
          </div>
          <v-spacer/>
          <div v-if="item.user && item.user_to_transfer_id">
            <t-avatar 
              :user="item.user"
            />
            <v-icon size="20" color="green" style="position: absolute;" class="ml-n3 mt-n1">
              mdi-check
            </v-icon>
            <v-icon size="20" class="mx-2" color="orange">mdi-transfer-right</v-icon>
          </div>
          <div class="d-flex mr-3">
            <t-avatar 
              v-if="item.user && !item.user_to_transfer_id"
              :user="item.user"
            />
            <t-avatar 
              v-else-if="item.user_to_transfer"
              :user="item.user_to_transfer"
            />
            <t-avatar
              v-else-if="item.user != null"
              :user="getUserData"
            />
            <v-icon v-if="item.status == 'Issued'" size="20" color="green" style="position: absolute;" class="ml-4 mt-n1">
              mdi-check
            </v-icon>
            <v-icon v-if="item.status == 'In_Transfer'" size="20" color="orange" style="position: absolute;" class="ml-4 mt-n1">
              mdi-clock-time-four-outline
            </v-icon>
          </div>
          <v-btn icon small color="orange" class="history-btn ml-auto" elevation="3" @click.stop="HistoryBtn(item)">
            <v-icon size="20">mdi-clipboard-text-clock</v-icon>
          </v-btn>

          <v-tooltip top v-if="item.status == 'Blocked' || item.status == 'block'">
            <template v-slot:activator="{on, attrs}">
              <v-btn v-on="on" v-bind="attrs" icon small class="ml-2" elevation="0" @click.stop="">
                <v-icon size="25" color="red">mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.DeviceDialog.DeviceBlockTitle") }}</span>
          </v-tooltip>
          
<!--           <v-btn icon small color="blue" class="history-btn ml-2" elevation="10" @click.stop="MenuBtn(item)">
            <v-icon>mdi-emoticon-devil-outline</v-icon>
          </v-btn>  -->
        </v-list-item>


        
        <div v-if="!is_loader_page" class="observer" v-observer="{ nextPage: MoreDevices}"/>

      </v-list>

    </v-card>

    <DeviceSettingCreateDialog 
      v-if="deviceSettingCreateVisible"
      :visible="deviceSettingCreateVisible"
      @close="closeDeviceSetting"
      @UPDATE_BTN="btn_update = true"
      :project="SelectProject ? SelectProject : (project ? project : '')"
      :selectDevice="SelectedDevice"
      :type="type"
      @addDevice="DeviceAdd"
      @UpdateDevice="DeviceUpdate"
      @transfer_user="transfer_userMethod"
      @CancelMoveProject="MoveCancelProject"
    />
    
    <HistoryDeviceDialog
      v-if="HistoryDeviceVisible"
      :visible="HistoryDeviceVisible"
      @close="HistoryDeviceVisible = false"
      :device="SelectDeviceHistory"
    />

  </v-dialog>
</template>

<script>
import notifications from '@/mixins/notifications';
import dialogMixin from '@/mixins/dialogMixin';
import DeviceSettingCreateDialog from './DeviceSettingCreateDialog.vue';
import projectsService from '@/services/request/projects/projectsService';
import usersMixin from '../taskDescriptionMixins/usersMixin';
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import HistoryDeviceDialog from './HistoryDeviceDialog.vue';
import * as XLSX from "xlsx";

export default {
  mixins: [notifications, dialogMixin, usersMixin],
  data: () => ({
    loading: false,
    deviceList: [],
    deviceSettingCreateVisible: false,
    type: '',
    IsDeviceBtn: true,
    SelectedDevice: null,
    CopySelectedDevice: null,
    btn_update: false,
    transfer_user: [],
    is_loader_page: false,
    pageMore: 0,
    searchDevice: null,
    total_device: null,
    HistoryDeviceVisible: false,
    SelectDeviceHistory: null,

    CommonProjects: [],
    SelectProject: null,
    loadOwner: true,
    page: 1,
    isLoader_page: false,
    lastProject: null,
    OnExport: '',
  }),
  props: {
    project: {
      require: false,
    }
  },
  components: {
    DeviceSettingCreateDialog,
    projectsService,
    HistoryDeviceDialog
  },
  mounted() {
    this.onMounted();
  },
  methods: {
    async onMounted() {
      await this.MoreProjects();
    },
    async MoreProjects() {
      await this.projectsListData(this.page++);
    },
    async projectsListData(NexPage) {
      const paramsMy = {
        per_page: 15,
        page: NexPage,
        owner: this.loadOwner,
      };
      
      let ProjGet = await projectsService.getList(paramsMy);
      
      if(ProjGet.length < 15) {
        this.loadOwner = false;
        this.page = 1;
      }
      else if(ProjGet.length < 15 && this.loadOwner == false) {
        this.isLoader_page = true;
      }
      this.CommonProjects = [...this.CommonProjects, ...ProjGet];
      
      //console.log("this.CommonProjects :", this.CommonProjects);
      this.CommonProjects.sort((a, b) => a.role - b.role);
      this.CommonProjects.unshift({
        id: "my-header",
        name: this.$t("home.created_by_me"),
        disabled: true,
        myProj: true,
      });
      this.CommonProjects.push({
        id: "guest-header",
        name: this.$t("home.guest_projects"),
        disabled: true,
        myProj: false,
      });

    },
    async DeviceAdd(item) {
      //this.deviceList.push(item);
      this.deviceList.unshift({
        ...item,
        user: this.getUserData,
      });
    },
    async SelectDevice(item) {
      this.SelectedDevice = item;
      this.CopySelectedDevice = JSON.parse(JSON.stringify(item));
      if(this.SelectProject) {
        this.type = 'header_select';
      } else {
        this.type = 'select';
      }
      this.deviceSettingCreateVisible = true;
    },
    async closeDeviceSetting() {
      try {
        if(this.btn_update == true) {
          this.deviceSettingCreateVisible = false;
          this.btn_update = false;
          return;
        }
        const index = this.deviceList.findIndex((existingItem) => existingItem.id === this.CopySelectedDevice.id);
        if (index !== -1) {
          this.deviceList.splice(index, 1);
          this.deviceList.splice(index, 0, this.CopySelectedDevice);
        }
      }
      catch(e) {}
      finally {
        this.deviceSettingCreateVisible = false;
      }
    },
    async transfer_userMethod (user) {
      this.transfer_user = user;
    },
    async AddDevice() {
      this.SelectedDevice = null;
      this.type = 'create';
      this.deviceSettingCreateVisible = true;
    },

    async MoveCancelProject(value) {
      const index = this.deviceList.findIndex((existingItem) => existingItem.id === value.id);
      if (index !== -1) {
        this.deviceList.splice(index, 1);
      }
    },

    async DeviceUpdate(item) {
      const index = this.deviceList.findIndex((existingItem) => existingItem.id === item.id);
      if (index !== -1) {
        this.deviceList[index] = item;
      }

      // const index = this.deviceList.findIndex((existingItem) => existingItem.id === item.id);
      // if (index !== -1) {
      //   this.deviceList.splice(index, 1);
      //   setTimeout(() => {
      //     this.deviceList.splice(index, 0, item);
      //   }, 80);
      // }
      // console.log("DeviceUpdate itemЧЧ :", item);
    },
    async MyDeviceMethod(pageMore) {
      this.loading = true; 
      this.IsDeviceBtn = false;

      if(this.SelectProject) {
        this.OnExport = 'HeaderSelectMy';
        let params = {};
        params.order = "true";
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAll(this.SelectProject.id, params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;

        this.loading = false;
        return;
      }

      if(!this.project) {
        this.OnExport = 'openHeaderMy';
        let params = {};
        params.order = "true";
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAllProjects(params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;
      } else {
        this.OnExport = 'openProjectMy';
        let params = {};
        params.order = "true";
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAll(this.project.id, params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;
      }
      this.loading = false;

    },
    async AllDeviceMethod(pageMore) {
      this.loading = true;
      this.IsDeviceBtn = true;

      if(this.SelectProject) {
        this.OnExport = 'HeaderSelectAll';
        let params = {};
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAll(this.SelectProject.id, params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;

        this.loading = false;
        return;
      }

      if(!this.project) {
        this.OnExport = 'openHeaderAll';
        let params = {};
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAllProjects(params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;
      } else {
        this.OnExport = 'openProjectAll';
        let params = {};
        params.page = pageMore;
        params.search = this.searchDevice;
        let { data, total } = await projectsService.getDeviceAll(this.project.id, params);
        this.deviceList = [...this.deviceList, ...data];
        this.total_device = total;
      }
      this.loading = false;
    },
    async selectProjectMethod(value){
      console.log("value project :", value.id);
      this.deviceList = [];
      this.pageMore = 0;
      this.is_loader_page = false;
      //await this.AllDeviceMethod(1);
    },
    async SearchDeviceMethod() {
      this.pageMore = 0;
      this.deviceList = [];
      this.is_loader_page = false;
      this.total_device = null;
      this.loading = true;
      
      if(this.searchDevice == '' || this.IsDeviceBtn == false) {
        await this.MoreDevices();
      }

          //if(this.SelectProject) {
      //  console.log("Обраний проєкт :", this.SelectProject.name);
//
      //  let params = {};
      //  params.search = this.searchDevice;
      //  let { data, total } = await projectsService.getDeviceAll(this.SelectProject.id, params);
      //  this.deviceList = [...this.deviceList, ...data];
      //  this.total_device = total;
//
      //  this.loading = false;
      //  return;
      //}
//
      //if(!this.project) {
      //  let params = {};
      //  params.search = this.searchDevice;
      //  let { data, total } = await projectsService.getDeviceAllProjects(params);
      //  this.deviceList = [...this.deviceList, ...data];
      //  this.total_device = total;
      //} else {
      //  let params = {};
      //  params.search = this.searchDevice;
      //  let { data, total } = await projectsService.getDeviceAll(this.project.id, params);
      //  this.deviceList = [...this.deviceList, ...data];
      //  this.total_device = total;
      //}
      this.loading = false;
    },

    async MoreDevices() {
      const currentLength = this.deviceList.length;
      if (currentLength == this.total_device) {
        this.is_loader_page = true;
        return;
      }
      this.pageMore++;
      
      if(this.IsDeviceBtn == true) {
        await this.AllDeviceMethod(this.pageMore);
      } else {
        await this.MyDeviceMethod(this.pageMore);
      }
    },
    async HistoryBtn(item) {
      this.SelectDeviceHistory = item;
      this.HistoryDeviceVisible = true;
    },
    
    async ExportListDevice() {
    try {
      let data, total;
      let params = {};
      params.per_page = 9999;
      params.search = this.searchDevice;

      switch(this.OnExport) {
        case 'openProjectAll':
          ({ data, total } = await projectsService.getDeviceAll(this.project.id, params));
        break;

        case 'openProjectMy':
          params.order = "true";
          ({ data, total } = await projectsService.getDeviceAll(this.project.id, params));
        break;

        case 'openHeaderAll':
          ({ data, total } = await projectsService.getDeviceAllProjects(params));
        break;

        case 'openHeaderMy':
          params.order = "true";
          ({ data, total } = await projectsService.getDeviceAllProjects(params));
        break;

        case 'HeaderSelectAll':
          ({ data, total } = await projectsService.getDeviceAll(this.SelectProject.id, params));
        break;

        case 'HeaderSelectMy':
          params.order = "true";
          ({ data, total } = await projectsService.getDeviceAll(this.SelectProject.id, params));
        break;

        default:
          console.log("Error Export file");
          return;
      }

      const projectName = this.project?.name || this.SelectProject?.name;
      const csvContent = this.convertToCSV(data, projectName);
      const blob = new Blob(["\ufeff" + csvContent], { type: "text/csv;charset=utf-16le;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${projectName || "Projects"}_DeviceList.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setSuccessNotification(this.$t("form.statisticSuccess"));
    } catch (e) {
      this.setErrorNotification(e.message);
    }
  },

  convertToCSV(data, projectName) {
    if (!data.length) return "";
    const delimiter = ";";
    const headers = [
      "Project Name",
      "Device Name",
      "Username",
      "Device model",
      "Status",
      "Created At",
      "Updated At"
    ].join(delimiter) + "\n";

    const formatDate = (dateString) => {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("uk-UA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      }).format(date);
    };

    const rows = data.map(device => {
      return [
        `"${projectName || device.project.name}"`,
        `"${device.name}"`,
        `"${device.user?.username || "N/A"}"`,
        `"${device.inventory_id || "N/A"}"`,
        `"${device.status}"`,
        `"${formatDate(device.created_at)}"`,
        `"${formatDate(device.updated_at)}"`
      ].join(delimiter);
    }).join("\n");

    return headers + rows;
  },

    async MenuBtn(item) {
      console.log("Інформація пристрою :", JSON.stringify(item, null, 2));
    },

    onCancel() {
      this.$emit("close");
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
  },
  computed: {
    ...mapGetters("auth", {
      getUserData: getterTypes.GET_USER_DATA,
    }),
  },
  watch: {
    CommonProjects(newVal) {
      this.lastProject = newVal[newVal.length - 2] || null;
    },
    IsDeviceBtn: {
      deep: true,
      handler(value) {
        this.deviceList = [];
        this.pageMore = 1;
        this.is_loader_page = false;
      }
    },
    SelectProject: {
      deep: true,
      handler(value) {
        if(value == '' || value == null) {
          this.SelectProject = null;
          this.deviceList = [];
          this.pageMore = 1;
          this.is_loader_page = false;
          this.AllDeviceMethod();
        }
      }
    }
    //searchDevice: {
    //  deep: true,
    //  handler(value) {
    //    setTimeout(() => {
    //      console.log("Search :", value);
    //    }, 300);
    //  }
    //}
  }
}
</script>

<style scoped lang="scss">
.hover-effect {
  background-color: rgba(255, 102, 0, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
  pointer-events: none;
}

.history-btn {
  min-width: 28px !important;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  .theme--light & {
    background-color: #fff;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .theme--dark & {
    background-color: #1e1e1e;
    border: thin solid rgba(255, 255, 255, 0.12);
  }
}
</style>