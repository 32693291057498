<template>
  <v-card class="testCase-panel" elevation="0">
    <v-card-title v-if="!testCase || Object.keys(inputTestCase).length == 0"
                  class="d-flex align-center justify-content-center pt-10">
      <strong>{{ $t('form.testCases.selectTestCase') }}</strong>
    </v-card-title>
    <v-card-title v-else class="py-1 d-fley justify-sm-space-between">
      <span style="line-height: 24px; font-size: 18px; min-width: 80%;" class="pr-3">
        <v-row no-gutters>
          <v-col class="pt-2 pb-2" cols="12">
            <v-text-field
                :label="$t('table.title')"
                v-model="inputTestCase.name"
                hide-details
                :maxlength="128"
                color="orange"
                outlined
                dense
            />
          </v-col>
        </v-row>
<!--        <v-btn-->
<!--            @click="confirmDelete = true;"-->
<!--            small-->
<!--            class="v-custom-tonal-btn&#45;&#45;error mr-2 px-2"-->
<!--            elevation="0"-->
<!--            :loading="false"-->
<!--            style="text-transform: none; height: 22px"-->
<!--        >-->
<!--          <v-icon class="text-white" size="16" color="error">-->
<!--            mdi-trash-can-outline-->
<!--          </v-icon>-->
<!--          {{ $t("btn.delete") }}-->
<!--        </v-btn>-->
      </span>
      <span class="testCase-date">
        {{
          $t("testCases.created_on") + ' ' +
          formattedDate(inputTestCase?.created_details?.timestamp) + ' ' +
          $t("testCases.created_by")
        }}
        <t-avatar size="18" :user="inputTestCase?.created_details?.user"/><br/>
        {{
          $t("testCases.last_modified_on") + ' ' +
          formattedDate(inputTestCase?.updated_details?.timestamp) + ' ' +
          $t("testCases.created_by")
        }}
        <t-avatar size="18" :user="inputTestCase?.updated_details?.user"/>
      </span>
    </v-card-title>
    <v-divider v-if="testCase && Object.keys(inputTestCase).length > 0" class="mx-4"></v-divider>
    <v-card-text v-if="testCase && Object.keys(inputTestCase).length > 0" class="py-2">
      <v-row class="d-flex align-center py-3 pl-4">
        <v-col cols="auto" class="pa-2">
          <v-col class="pb-0 task-desc-title">
            {{ $t("form.taskDescriptionDialog.tags") }}
          </v-col>
          <v-col cols="auto">
            <t-tag
                v-for="label in inputTestCase.assigned_labels"
                :key="label.id"
                :tag="{ name: label.name, bg: label.color }"
            />
            <v-btn
                icon
                class="mb-1 btn-add-label"
                @click="labelsList = true"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn
            >
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 7" :lg="$vuetify.breakpoint.width <= 1544 ? '7' : '8'">
          <v-textarea
              outlined
              color="orange"
              dense
              :maxLength="2000"
              v-model="inputTestCase.steps"
              :rows="$vuetify.breakpoint.smAndDown ? 10 : 10"
              :label="$t('testCases.steps')"
              hide-details
              height="600px"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3" :lg="$vuetify.breakpoint.width <= 1544 ? '3' : '4'">
          <v-textarea
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'ml-2'"
              outlined
              color="orange"
              dense
              :maxLength="2000"
              v-model="inputTestCase.results"
              :rows="$vuetify.breakpoint.smAndDown ? 4 : 10"
              :label="$t('testCases.expected_result')"
              hide-details
              height="600px"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col cols="12">
          <v-btn
              class="btn-orange-bg"
              style="min-width: 120px"
              :disabled="!changed"
              @click="onSave"
          >
            {{ $t("btn.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmDialog
        v-if="confirmDelete"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        @onConfirm="onDeleteTestCase(testCase)"
        @onCancel="confirmDelete = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template>
    </ConfirmDialog>
    <labelsListDialog
        v-if="labelsList"
        :visible="labelsList"
        @close="labelsList = false"
        :projectId="projectId"
        :task="inputTestCase"
        :location="'testCase'"
        @addLabelToTestCase="addLabelToTestCase"
        @deleteLabelFromTestCase="deleteLabelFromTestCase"
    />
  </v-card>
</template>

<script>
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import notifications from "@/mixins/notifications";
import testCasesService from "@/services/request/testCases/testCasesService";
import EventBus from "@/events/EventBus";
import testSuitsCasesMixin from "@/mixins/testSuitsCasesMixin";
import labelsListDialog from "@/components/boards/dialogs/labelsListDialog.vue";
import {mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";
import boardsService from "@/services/request/boards/boardsService";

export default {
  data: () => ({
    changed: false,
    confirmDelete: false,
    inputTestCase: {},
    copyTestCase: {},
    labelsList: false
  }),
  components: {
    labelsListDialog,
    ConfirmDialog,
  },
  mixins: [notifications, testSuitsCasesMixin],
  props: {
    testCase: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectId: {
      required: true,
    },
  },
  mounted() {
    EventBus.$on("afterDeleteSuitCase", () => {
      this.inputTestCase = {};
      this.copyTestCase = {};
    });

    EventBus.$on('updateTestCase', (item) => {
      this.inputTestCase = {
        ...this.nullToEmptyString(item)
      };
      this.copyTestCase = {
        ...this.nullToEmptyString(item)
      };
    });

    this.inputTestCase = {
      ...this.nullToEmptyString(this?.testCase)
    };
    this.copyTestCase = {
      ...this.nullToEmptyString(this?.testCase)
    };
  },
  methods: {
    async addLabelToTestCase(label) {
      try {
        const formData = new FormData();
        formData.append("label_id", label.id);
        await testCasesService.addLabelToTestCase(this.projectId, this.inputTestCase.test_suit_id, this.inputTestCase.id, formData);
        this.inputTestCase.assigned_labels.push(label);
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async deleteLabelFromTestCase(label) {
      try {
        const formData = new FormData();
        formData.append("label_id", label.id);
        await testCasesService.deleteLabelFromTestCase(this.projectId, this.inputTestCase.test_suit_id, this.inputTestCase.id, formData);
        const searchItem = this.inputTestCase.assigned_labels.findIndex(
            (item) => item.id === label.id
        );
        this.inputTestCase.assigned_labels.splice(searchItem, 1);
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    nullToEmptyString(obj) {
      return Object.fromEntries(
          Object.entries(obj || {}).map(([key, value]) => [key, value === null ? '' : value])
      );
    },
    async onSave() {
      try {
        const formData = new FormData();
        const changedData = this.$getChangedData(this.inputTestCase, this.copyTestCase);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        formData.append("_method", 'patch');
        if (this.inputTestCase.card_id) {
          formData.append("card_id", this.inputTestCase.card_id);
        }
        await testCasesService.updateTestCase(this.projectId, this.inputTestCase.test_suit_id, this.inputTestCase.id, formData);
        this.copyTestCase = {...this.inputTestCase};
        this.inputTestCase = {...this.copyTestCase};
        this.onUpdateCasePanel(this.testCase);
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
      } catch (e) {
        console.log(e)
        this.setErrorNotification(this.$t("form.userisundefined"));
      }
    },
    formattedDate(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
  },
  watch: {
    inputTestCase: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyTestCase, this.inputTestCase);
      },
    },
    testCase: {
      handler(e) {
        if (e) {
          this.inputTestCase = {...this.nullToEmptyString(e)};
          this.copyTestCase = {...this.nullToEmptyString(e)};
        }
      }
    }
  },
  computed: {
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID
    }),
  }
};
</script>

<style lang="scss">
.testCase-panel {
  min-width: 70%;
  width: 100%;
}

.testCase-date {
  font-size: 14px;
  line-height: 20px;
  
  .theme--light & {
    color: #6b6a6a;
  }

  .theme--dark & {
    color: #ccc;
  }
}
</style>
