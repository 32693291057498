// eslint-disable-next-line import/prefer-default-export
export const userNormalizer = (User) => ({
  name: User?.name,
  surname: User?.surname,
  username: User?.username,
  email: User?.email,
  profile_image: User?.profile_image,
  id: User?.id,
  role: User.role ? User?.role : 1,
  task_assignment_emails: User?.task_assignment_emails ? User?.task_assignment_emails : 0,
});
